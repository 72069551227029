import { AnswerSetBuilder, DocumentDrafterQuestionnaire, RequestForOfferQuestionnaireProvider, Tender, useBackend } from '@dims/components';
import { Tender5086 } from '@/models/Tender';
import RequestQuestionnaireValidator from './RequestQuestionnaireValidator';

class RequestQuestionnaireProvider implements RequestForOfferQuestionnaireProvider {
  backend = useBackend();
  constructor(readonly tender: Tender5086) { }

  activatorLabel = 'Leveringskontrakt og Opfordring';
  hasPreviewableCsr = false;

  async getInitialPushAnswers(_answers: AnswerSetBuilder): Promise<void> {
    return Promise.resolve();
  }

  getValidator(questionnaire: DocumentDrafterQuestionnaire) {
    return new RequestQuestionnaireValidator(this.tender, questionnaire);
  }
}

export default function getProvider(tender: Tender) {
  return new RequestQuestionnaireProvider(tender);
}
