// NOTE: Paths should be file-relative
// since we are also importing this file from the cypress project
import { BPQCriteriaEnum } from '../services/bpqCriteria';

/** Specifies IDs from the delivery agreement
 * Question IDs are named 'id' + a descriptive name for the question
 * Answer IDs are named 'id' + a descriptive name for the question
 * + 'Answer' + a descriptive name for the answer
 * Some IDs can be accessed through corresponding enums
*/
class DeliveryAgreementIds {
  readonly idAPV = 'b1ec6039-de3f-4798-9d63-eb64f17f7ef5';
  readonly idCost = '3d48bde5-738b-4a49-b86a-dfc76cf06991';
  readonly idCarsBoughtInitially = '50a506b3-5fd7-4b25-a00e-6bc3ed5338a8';
  readonly idOptionalCarsAfterAgreement = 'ed367e80-9b55-422e-bc57-cc832bd5a646';
  readonly idPriceSpanPct = '8e4ed54c-6f23-4423-b4c4-a52588b89d22';
  readonly idDeliveryDateTestVehicle = 'd0243022-fba9-4e79-8a9d-c69e253f508d';
  readonly idAwardingCriteria = '23e04737-3f36-4e96-bbc7-686da095253b';
  readonly idAwardingCriteriaAnswer_TCO = 'b024337c-cc3c-4b69-be50-6851524e37d9';
  readonly idAwardingCriteriaAnswer_BPQ = '6c4af246-c7e4-4cc2-92cf-7aa6514242e9';
  readonly idDeadlineQuestions = '85b2b0d1-7181-4c19-a2ba-791756d0e595';

  readonly idBpqRatioPercentage = '74623023-0671-46ce-b6bd-f4df4f3c93a6';
  readonly idUserTestParticipants = '69377404-a5fe-4504-9c5d-15539b9b0afc';
  readonly idDeliveryDaysAfterContract = 'c3f8e640-8ecb-40c6-af8d-413246b2d1e8';

  // Evaluering af kvalitet
  readonly idQualityEvaluation = 'aea9ad52-52ae-4600-b002-7da96b06e75a';
  readonly idQualityEvaluationAnswer_DrivingCharacteristics = '2e8a64de-30c0-4bc3-840e-30000670287c'; // Køreegenskaber
  readonly idQualityEvaluationAnswer_DriverSeatAdjustment = '14bc2ab2-b323-4e7d-baac-2afc257d1365'; // Førersædejustering
  readonly idQualityEvaluationAnswer_EntryExitConditions = 'd46727df-f566-4120-b0a3-9a99e2054569'; // Ind- og udstigningsforhold
  readonly idQualityEvaluationAnswer_View = '579c47b2-747b-47df-8480-64b9e657058a'; // Udsyn
  readonly idQualityEvaluationAnswer_CabinNoise = 'c24798e7-10a8-47ea-8980-9dd22e8d48e3'; // Kabinestøj
  readonly idQualityEvaluationAnswer_Instrumentation = 'd9e0f456-3e31-49c9-8d2f-74b1bf6e3eea'; // Instrumentering
  readonly idQualityEvaluationAnswer_Comfort = 'f5dc48b0-82c3-454a-819c-7476855c54d3'; // Komfort

  // BPQ
  readonly idDrivingCharacteristics = '80e5c129-21bf-414f-92b3-aec05898a216';
  readonly idDriverSeatAdjustment = '38bbe919-3c7c-4a9d-a9e0-5dee9acc086f';
  readonly idEntryExitConditions = '8b809245-1cd2-43ae-bc16-f83eb9b7d25c';
  readonly idView = '0ed31da0-b05c-4cd3-b166-f8f9ceac6b93';
  readonly idCabinNoise = 'bdf0beff-8df8-4553-abec-1473537a389e';
  readonly idInstrumentation = '1000e377-0459-470e-a9ef-8881477fa7cc';
  readonly idComfort = '60584f4a-de42-49b7-b795-853bc86e4165';

  getIdForBPQCriteria(criteria: BPQCriteriaEnum): string {
    switch (criteria) {
      case 'DrivingCharacteristics': return this.idDrivingCharacteristics;
      case 'DriversSeatAdjustment': return this.idDriverSeatAdjustment;
      case 'EntryExitConditions': return this.idEntryExitConditions;
      case 'View': return this.idView;
      case 'CabinNoise': return this.idCabinNoise;
      case 'Instrumentation': return this.idInstrumentation;
      case 'Comfort': return this.idComfort;
      default: throw Error('Invalid BPQCriteria');
    }
  }
}

const deliveryAgreementIds = new DeliveryAgreementIds();
export default deliveryAgreementIds;
