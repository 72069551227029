import { Offer, hasValue } from '@dims/components';
import { VehicleType } from '@/models';
import { isPropellantElectric, isPropellantICE } from '@/models/PropellantTypes';
import DeliveryConditionTypesEnum from '@/models/DeliveryConditionTypes';
import { OfferData5086 } from '@/models/Offer';
import { Tender5086 } from '@/models/Tender';

// Not Empty
const ne = (value: string | undefined, name = ''): boolean => {
  const empty = !hasValue(value) || value.trim() === '';
  if (empty) {
    console.info(`Validation error. ${name} is empty`);
  }
  return !empty;
};

// Not Undefined If
const nui = (value: unknown, condition: unknown, name = ''): boolean => {
  if (condition && (value === undefined || value === null || value === '')) {
    console.info(`Validation error. ${name} is undefined, condition:`, condition);
    return false;
  }
  return true;
};

const offerService = {
  offerHasQuestionnaire: false,
  areOfferDetailsValid: (offer: Offer | null, tender: Tender5086): boolean => {
    if (offer == null) {
      return false;
    }
    const d = offer.data as OfferData5086;
    const td = tender.data;
    d.propellantType = d.propellantType ?? (td.propellantTypes?.length === 1 ? td.propellantTypes[0] : undefined);
    return ne(d.brand, 'brand')
      && ne(d.model, 'model')
      && ne(d.variant, 'variant')
      && ne(d.equipmentLine, 'equipmentLine')
      && ne(d.engineSize, 'engineSize')
      && ne(d.power, 'power')
      && nui(d.priceTotal, !td.usesOwnSpecification, 'priceTotal')
      && nui(d.greenOwnerTax, !td.usesOwnSpecification && ((td.greenOwnerTax ?? '').toLowerCase() === 'nej'), 'greenOwnerTax')
      && nui(d.registrationTax, !td.usesOwnSpecification, 'registrationTax')
      && nui(d.priceBuyback, !td.usesOwnSpecification && td.priceBuyback, 'priceBuyback')
      && nui(d.priceService, !td.usesOwnSpecification && td.serviceAgreementDescriptionPeriod, 'priceService')
      && nui(d.priceRepairs, !td.usesOwnSpecification && td.repairmentAgreementPeriod, 'priceRepairs')
      && nui(d.priceCollectDeliverService, !td.usesOwnSpecification && td.pickupDeliverServicePeriod, 'priceCollectDeliverService')
      && nui(d.priceServicePeriod, !td.usesOwnSpecification && td.extendedWarrantyPeriod, 'priceServicePeriod')
      && nui(d.priceTireAgreement, !td.usesOwnSpecification && td.tireAgreementPeriod, 'priceTireAgreement')
      && nui(d.priceDelivery, !td.usesOwnSpecification, 'priceDelivery')
      && nui(d.priceDeliveryAtCustomer, !td.usesOwnSpecification && td.deliveryCondition === DeliveryConditionTypesEnum.DELIVERY_AT_CUSTOMER, 'priceDeliveryAtCustomer')
      && hasValue(d.propellantType)
      && nui(d.propellantType, (td.propellantTypes ?? []).length > 1, 'propellantType')
      && nui(d.consumptionICE, isPropellantICE(d.propellantType), 'consumptionICE')
      && nui(d.consumptionElectric, isPropellantElectric(d.propellantType), 'consumptionElectric1')
      && nui(d.chargingStationPrice, isPropellantElectric(d.propellantType) && td.chargingStationPrice, 'chargingStationPrice')
      && nui(d.chargingSubscription, isPropellantElectric(d.propellantType) && td.chargingSubscription, 'chargingSubscription')
      && nui(d.consumptionElectric, td.whKmElectricConsumption, 'consumptionElectric2')
      && nui(d.vehicleClassType, td.vehicleType !== VehicleType.OTHER_VEHICLE, 'vehicleClassType')
      && (nui(d.emission, td.vehicleType !== VehicleType.OTHER_VEHICLE, 'emission'));
  },

  allNonValidDetails: (offer: Offer, tender: Tender5086): string[] => {
    const result: string[] = [];
    const d = offer.data as OfferData5086;
    const td = tender.data;
    d.propellantType = d.propellantType ?? (td.propellantTypes?.length === 1 ? td.propellantTypes[0] : undefined);
    if (!hasValue(offer.specificationAccepted)) result.push('Kravene i kravspecifikation er ikke accepteret');
    if (!ne(d.brand)) result.push('Mærke');
    if (!ne(d.model)) result.push('Model');
    if (!ne(d.variant)) result.push('Variant');
    if (!ne(d.equipmentLine)) result.push('Udstyrslinje');
    if (!ne(d.engineSize)) result.push('Motorstørrelse');
    if (!ne(d.power)) result.push('HK/Moment');
    if (!nui(d.propellantType, (td.propellantTypes ?? []).length > 1)) result.push('Brændstoftype');
    if (d.propellantType !== undefined) {
      if (!nui(d.consumptionICE, isPropellantICE(d.propellantType))) result.push('Brændstofforbrug');
      if (!nui(d.consumptionElectric, isPropellantElectric(d.propellantType))) result.push('Elektrisk forbrug');
      if (!nui(d.chargingStationPrice, isPropellantElectric(d.propellantType) && td.chargingStationPrice)) result.push('Ladestandere');
      if (!nui(d.chargingSubscription, isPropellantElectric(d.propellantType) && td.chargingSubscription)) result.push('Ladeabbonnement');
    }
    if (!nui(d.vehicleClassType, td.vehicleType !== VehicleType.OTHER_VEHICLE)) result.push('Køretøjsklasse');
    if (!nui(d.emission, td.vehicleType !== VehicleType.OTHER_VEHICLE)) result.push('Emission');
    if (!nui(d.priceTotal, !td.usesOwnSpecification)) result.push('Samlet pris');
    if (!nui(d.greenOwnerTax, !td.usesOwnSpecification && ((td.greenOwnerTax ?? '').toLowerCase() === 'nej'))) result.push('Grøn ejerafgift');
    if (!nui(d.registrationTax, !td.usesOwnSpecification)) result.push('Registreringsafgift');
    if (!nui(d.priceBuyback, !td.usesOwnSpecification && td.priceBuyback)) result.push('Tilbagekøbspris');
    if (!nui(d.priceService, !td.usesOwnSpecification && td.serviceAgreementDescriptionPeriod)) result.push('Pris på serviceaftale');
    if (!nui(d.priceRepairs, !td.usesOwnSpecification && td.repairmentAgreementPeriod)) result.push('Pris på reparationsaftale');
    if (!nui(d.priceCollectDeliverService, !td.usesOwnSpecification && td.pickupDeliverServicePeriod)) result.push('Pris på hente/bringe-service');
    if (!nui(d.priceServicePeriod, !td.usesOwnSpecification && td.extendedWarrantyPeriod)) result.push('Pris på udvidet serviceperiode');
    if (!nui(d.priceTireAgreement, !td.usesOwnSpecification && td.tireAgreementPeriod)) result.push('Pris på dækaftale');
    if (!nui(d.priceDelivery, !td.usesOwnSpecification)) result.push('Leveringsomkostninger');
    if (!nui(d.priceDeliveryAtCustomer, !td.usesOwnSpecification && td.deliveryCondition === DeliveryConditionTypesEnum.DELIVERY_AT_CUSTOMER)) result.push('Pris for levering hos kunde');
    return result;
  },
};

export default offerService;
