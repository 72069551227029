<template>
  <BpqDocumentationDialogBase
    :offer
    :index
    :isOfferConditional="conditionalOffers.includes(offer)"
    :criterias="createCriterias()"
    :offerScore="calculateTotalScore(conditionalOffers, offer, tender5086, evaluationStore.getOfferEvaluation(offer))"
    :qualityPercentage="bpqRatioPercent"
    :firstColumnWidth="5"
    :pricePoints="{
      minPoints: 1,
      maxPoints: 10,
      minPrice: lowestPrice,
      tcoPrice: calculateTCOPrice(offer, tender5086),
      alpha,
      beta,
      priceScore: calculatePriceScore(conditionalOffers, offer, tender5086),
    }"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { WeightedScoreViewModel, BpqDocumentationDialogBase, useEvaluationStore } from '@dims/components';
import {
  qualityRatioPercentage,
  calculatePriceScore,
  calculateTCOPrice,
  calculateTotalScore,
  getExtendedPriceSpan,
} from '@/services/utils5086';
import { Tender5086 } from '@/models/Tender';
import { Offer5086 } from '@/models/Offer';
import bpqCriteria from '@/services/bpqCriteria';

const { offer, conditionalOffers } = defineProps<{
  offer: Offer5086,
  conditionalOffers: Offer5086[],
  index: number,
}>();

const evaluationStore = useEvaluationStore();

const tender5086 = computed(() => evaluationStore.tender as Tender5086);

function createCriterias() {
  const offerEvaluation = evaluationStore.getOfferEvaluation(offer);
  if (offer.isEvaluated && offerEvaluation) {
    return bpqCriteria.getCategories(tender5086.value.data, offerEvaluation)
      .map((c) => new WeightedScoreViewModel(c.key, c.label, c.score, c.weight));
  }

  return bpqCriteria.getEmptyCategories(tender5086.value.data)
    .map((c) => new WeightedScoreViewModel(c.key, c.label, c.score, c.weight));
}

const lowestPrice = computed(() => {
  const prices = conditionalOffers.map((o) => calculateTCOPrice(o, tender5086.value));
  return Math.min(...prices);
});

const bpqRatioPercent = computed(() => qualityRatioPercentage(tender5086.value));

const alpha = computed(() => {
  const prices = conditionalOffers.map((o) => calculateTCOPrice(o, tender5086.value));
  const extendedPriceSpan = getExtendedPriceSpan(prices, lowestPrice.value, tender5086.value);
  return (
    (1 - 10)
      / (lowestPrice.value
        * (1 + (extendedPriceSpan ?? ((tender5086.value.data.bpqCostsPercentage ?? 0) / 100)))
        - lowestPrice.value)
  );
});

const beta = computed(() => 10 - alpha.value * lowestPrice.value);
</script>
