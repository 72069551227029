<template>
  <EvaluationOfferListBase
    :tender="evaluationStore.tender"
    :offers="evaluationStore.offers"
    :offersEvaluations="evaluationStore.offersEvaluations"
    :additionalHeaders
    :isLoadingOffers="evaluationStore.isLoadingOffers"
    @fetchOffers="evaluationStore.fetchAndSortOffers(calculator)"
    @updateTab="emit('updateTab', $event)"
  >
    <template #additional-columns="{ offer, isMobile }: { offer: Offer5086, isMobile: boolean }">
      <template v-if="isMobile">
        <li class="flex-item" data-label="Mærke">
          {{ offer.data.brand }}
        </li>
        <li class="flex-item" data-label="Model">
          {{ offer.data.model }}
        </li>
        <li class="flex-item" data-label="Variant">
          {{ offer.data.variant }}
        </li>
      </template>
      <template v-else>
        <td>
          {{ offer.data.brand }}
        </td>
        <td>
          {{ offer.data.model }}
        </td>
        <td>
          {{ offer.data.variant }}
        </td>

      </template>
    </template>
    <template #tco> </template>
    <template #bpq="{ offer, conditionalOffers, index }">
      <BpqDocumentationDialog
        :offer
        :conditionalOffers
        :index
      />
    </template>
  </EvaluationOfferListBase>
</template>

<script setup lang="ts">
import { EvaluationOfferListBase, useEvaluationStore } from '@dims/components';
import BpqDocumentationDialog from './BpqDocumentationDialog.vue';
import { Offer5086 } from '@/models/Offer';
import { getCalculator } from '@/services';

const emit = defineEmits<{
  updateTab: [string],
}>();

const calculator = getCalculator();

const evaluationStore = useEvaluationStore();

const additionalHeaders = [
  { text: 'Mærke', sortable: false, value: 'brand', width: '8%' },
  { text: 'Model', sortable: false, value: 'model', width: '8%' },
  { text: 'Variant', sortable: false, value: 'variant', width: '10%' },
];

</script>
